<template>
  <div class="container-fluid discount-codes">
    <div class="row">
      <div class="col">
        <div class="card border-0 shadow-sm">
          <div class="card-header">
            <div class="col-auto my-auto d-flex align-items-center">
              <h5>Discount codes</h5>
            </div>
          </div>

          <div class="card-body">
            <div class="row">
              <div class="col">
                <div v-if="!loading && discountCodes.length < 1" class="text-center">
                  <h6>
                    There are currently no discount codes, please add one below.
                  </h6>
                </div>
                <div class="table-responsive">
                  <table class="table table-sm">
                    <thead>
                      <tr>
                        <th scope="col">Code</th>
                        <th scope="col">Discount</th>
                        <th scope="col">Supplier</th>
                        <th scope="col"></th>
                      </tr>
                    </thead>
                    <tbody v-if="!loading && discountCodes.length > 0">
                      <tr v-for="c in discountCodes" v-bind:key="c.id">
                        <td>{{ c.code }}</td>
                        <td>{{ c.discount_percent }}%</td>
                        <td>{{ c.supplier ? c.supplier.name : "" }}</td>
                        <td>
                          <i
                            class="far fa-trash text-danger"
                            @click="deleteDiscountCode(c)"
                          ></i>
                        </td>
                      </tr>
                    </tbody>
                    <tbody v-if="loading" class="">
                      <tr>
                        <td colspan="4">
                          <loading class="my-4"></loading>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div class="row mt-4 mb-5 discount-code-fields">
              <div class="col">
                <p class="mb-2">Enter discount code</p>
                <input
                  type="text"
                  v-model="code"
                   @input="toUpperCase($event)"
                  class="form-control"
                  placeholder="DISCOUNT CODE"
                />
              </div>
              <div class="col">
                <p class="mb-2">Select a supplier</p>
                <select v-model="selectedSupplier" class="form-control">
                  <option v-if="!suppliers">No suppliers found</option>
                  <option v-for="supplier in suppliers" v-bind:key="supplier.id" v-bind:value="supplier">
                    {{ supplier.name }}
                  </option>
                </select>
              </div>

              <div class="col">
                <p class="mb-2">Discount percent</p>
                <input
                  type="number"
                  v-model="percent"
                  class="form-control"
                  placeholder="eg. 12"
                />
              </div>
            </div>

            <div class="row text-end">
              <div class="col">
                <button
                  type="submit"
                  class="btn btn-primary"
                  @click="addDiscountCode"
                  :disabled="!code || !selectedSupplier || !percent"
                >
                  <i class="fas fa-plus me-1"></i>Add Discount Code
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Loading from "@/views/components/loader/Loading";

  export default {
    props: ["path", "user"],
    data() {
      return {
        code: null,
        discountCodes: [],
        loading: true,
        percent: null,
        selectedSupplier: null,
        suppliers: null,
      };
    },
    methods: {
      fetchDiscountCodes() {
        this.$axios
          .get(
            process.env.VUE_APP_API_URL +
              "/settings/discount-codes/get-discount-codes"
          )
          .then(({ data }) => {
            this.discountCodes = data;
            this.loading = false;
          });
      },
      fetchSuppliers() {
        this.$axios
          .get(process.env.VUE_APP_API_URL + "/suppliers/api")
          .then(({ data }) => {
            this.suppliers = data;
          });
      },
      addDiscountCode() {
        this.$axios
          .post(
            process.env.VUE_APP_API_URL +
              "/settings/discount-codes/add-discount-code",
            {
              code: this.code,
              supplier: this.selectedSupplier,
              percent: this.percent,
            }
          )
          .then(({ data }) => {
            this.$EventBus.$emit("alert", data);
            this.fetchDiscountCodes();

            this.code = null;
            this.selectedSupplier = null;
            this.percent = null;
          });
      },
      deleteDiscountCode(obj) {
        this.$axios
          .post(
            process.env.VUE_APP_API_URL +
              "/settings/discount-codes/delete-discount-code",
            {
              discount_code_id: obj.id,
            }
          )
          .then(({ data }) => {
            this.$EventBus.$emit("alert", data);
            this.discountCodes.splice(this.discountCodes.indexOf(obj), 1);
          });
      },
      toUpperCase(e) {
        const start = e.target.selectionStart;
        e.target.value = e.target.value.toUpperCase();
        this.code = e.target.value;
        e.target.setSelectionRange(start, start);
      },
    },
    mounted() {
      this.fetchDiscountCodes();
      this.fetchSuppliers();
    },
    components: {
      Loading,
    },
  };
</script>

<style scoped>
  .table thead > tr > th {
    border-top: 0px;
    border-bottom: 1px;
  }

  @media (max-width: 768px) {
    .discount-code-fields {
      flex-direction: column;
    }
  }
</style>
